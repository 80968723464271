<!-- 时间线弹窗 -->

<template>
  <div class="timelinePopup">
    <div class="timelinePopup_container">
      <div class="timelinePopup_container_title">
        <div class="timelinePopup_container_title_left">Time Line</div>
        <img class="timelinePopup_container_title_close" src="../../../assets/images/supplier/quotation/if-close.png" alt="" @click="CloseDialog()">
      </div>

      <div class="timelinePopup_container_detail">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="name" label="Status" width="240" />
          <el-table-column prop="co" label="Carbon Emission (kg)" width="200" >
            <template #default="scope">
              {{scope.row.co === null ? 0 : thousands(scope.row.co)}}
            </template>
          </el-table-column>
          <el-table-column prop="linATime" label="Expected" />
          <el-table-column prop="lineBTime" label="Actual" />
<!--          <el-table-column prop="timeLineA" label="Time Line C" />-->
        </el-table>

      </div>
    </div>
  </div>
</template>

<script setup name="timelinePopup">
import { ref, defineEmits, defineProps, onMounted } from 'vue'
import {getOrderTimeLineAll} from '@/api/order'
import {thousands} from "@/utils/thousands";
const props = defineProps({
  orderId: {
    type: Number,
    default: 0
  }
})

// 表格数据
let tableData = ref()

// 获取数据
const getTableData = async () => {

  const result = await getOrderTimeLineAll(props.orderId)
  if(result.code === 200){
     tableData.value = result.data
  }

/*
  tableData.value = [{
    status: 'Order Created',
    carbonEmission: '0',
    timeLineA: '2023-07-01  09:00:00',
    timeLineB: '2023-07-01  09:32:06',
    timeLineC: '2023-07-01  09:59:52'
  },
    {
      status: 'Raw Material Process',
      carbonEmission: '23456',
      timeLineA: '2023-07-01  09:00:00',
      timeLineB: '2023-07-01  09:32:06',
      timeLineC: '2023-07-01  09:59:52'
    },
    {
      status: 'Sample Production',
      carbonEmission: '0',
      timeLineA: '2023-07-01  09:00:00',
      timeLineB: '2023-07-01  09:32:06',
      timeLineC: '2023-07-01  09:59:52'
    }]*/
}

// 关闭弹窗
const emit = defineEmits(['close'])
const CloseDialog = () => {
  emit('close')
}

onMounted(() => {
  console.log(props.orderId, '接收的orderId')

  getTableData()

})

</script>

<style lang="scss" scoped>
.timelinePopup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%);
  line-height: 1;
  text-align: left;
  overflow-y: scroll;

  &_container {
    width: 1000px;
    margin: 130px auto 0;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 300px;

    &_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px;
      font-size: 16px;
      font-weight: 700;
      background-color: rgba(248, 248, 248, 1);

      &_close {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    &_detail {
      width: 960px;
      margin: 20px;
      padding-bottom: 30px;
    }
  }
}
</style>

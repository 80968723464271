<!-- 碳足迹跟踪 -->
<template>
  <div class="ShippingInformation">
    <div class="ShippingInformation_container">
      <div class="ShippingInformation_container_map">
        <!-- 默认图片 -->
        <!-- <img src="../../assets/images/map.png" alt=""> -->

        <!-- 引入网站 -->
        <!-- <iframe src="https://www.searates.com/container/tracking/" frameborder="0" style="width: 100%; height: 80vh" scrolling="no"></iframe> -->

        <!-- 引入网站并添加集装箱号 --- 有问题的话使用上面的，让用户自行输入集装箱号 -->
        <iframe :src=iframeUrl frameborder="0" style="width: 100%; height: 80vh" scrolling="no"></iframe>
      </div>
      <div class="ShippingInformation_container_progress">
        <div class="ShippingInformation_container_progress_top">
          <!-- 发货国家 发货时间 -->
          <div class="ShippingInformation_container_progress_top_left">
            <p class="ShippingInformation_container_progress_top_title">China</p>
            <p class="ShippingInformation_container_progress_top_date">{{ timeline[0]?.createTime }}</p>
          </div>

          <!-- 预计到达 -->
          <div class="ShippingInformation_container_progress_top_right">
            <p class="ShippingInformation_container_progress_top_title">Ireland</p>
            <p class="ShippingInformation_container_progress_top_date">{{ timeline[timeline.length - 1]?.createTime }}</p>
          </div>
        </div>

        <!-- 进度  -->
        <div ref="scrollContainer" class="ShippingInformation_container_progress_bottom" @mousewheel="scrollHorizontally">

          <div ref="scrollContent" class="ShippingInformation_container_progress_bottom_box" v-if="order.orderType === 1">
            <div class="ShippingInformation_container_progress_bottom_box_card" v-for="(item, index) in stateOptionsOnlySample " :key="index">
              <div class="ShippingInformation_container_progress_bottom_box_card_line">
                <div :class="['ShippingInformation_container_progress_bottom_box_card_line_round', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_line_roundActive' : 'ShippingInformation_container_progress_bottom_box_card_line_roundAlready',
                  index-1 > orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_line_roundNot' : '']">
                </div>
              </div>
              <div :class="['ShippingInformation_container_progress_bottom_box_card_item', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_itemActive' : '']">
                <div class="ShippingInformation_container_progress_bottom_box_card_item_top">
                  <div class="ShippingInformation_container_progress_bottom_box_card_item_top_co2">
                    <div class="ShippingInformation_container_progress_bottom_box_card_item_top_co2_icon">
                      <img v-show="index-1 != orderStatus" src="../../assets/images/home/carbonFootprint.png" alt="">
                      <img v-show="index-1 == orderStatus" src="../../assets/images/home/carbonFootprint_white.png" alt="">
                    </div>
                    <div class="ShippingInformation_container_progress_bottom_box_card_item_top_co2_text">
                      <p :class="['ShippingInformation_container_progress_bottom_box_card_item_top_co2_text_title', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_itemActive_top_co2_text_title' : '']">
                        {{ thousands(item.co) }}kg</p>
                      <p :class="['ShippingInformation_container_progress_bottom_box_card_item_top_co2_text_text', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_itemActive_top_co2_text_text' : '']">
                        CO<sub>2</sub></p>
                    </div>
                  </div>
                  <div class="ShippingInformation_container_progress_bottom_box_card_item_top_lable">
                    <img v-show="index-1 != order.orderStatus" src="../../assets/images/home/lable.png" alt="">
                    <img v-show="index-1 == order.orderStatus" src="../../assets/images/home/lable1.png" alt="">
                  </div>
                </div>

                <!-- 状态 -->
                <div :class="['ShippingInformation_container_progress_bottom_box_card_item_status', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_itemActive_status' : '']">
                  {{ item.state }}
                </div>

                <!-- 最后是日期和地点 -->
                <div :class="['ShippingInformation_container_progress_bottom_box_card_item_bottom', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_itemActive_bottom' : '']">
                  <div class="ShippingInformation_container_progress_bottom_box_card_item_bottom_date">
                    {{ item.time }}
                  </div>
                  <!--                  <div class="ShippingInformation_container_progress_bottom_box_card_item_bottom_location">-->
                  <!--                    China-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </div>

          <div ref="scrollContent" class="ShippingInformation_container_progress_bottom_box" v-if="order.orderType === 2">
            <div class="ShippingInformation_container_progress_bottom_box_card" v-for="(item, index) in stateOptions " :key="index">
              <div class="ShippingInformation_container_progress_bottom_box_card_line">
                <div :class="['ShippingInformation_container_progress_bottom_box_card_line_round', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_line_roundActive' : 'ShippingInformation_container_progress_bottom_box_card_line_roundAlready',
                  index-1 > orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_line_roundNot' : '']">
                </div>
              </div>
              <div :class="['ShippingInformation_container_progress_bottom_box_card_item', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_itemActive' : '']">
                <div class="ShippingInformation_container_progress_bottom_box_card_item_top">
                  <div class="ShippingInformation_container_progress_bottom_box_card_item_top_co2">
                    <div class="ShippingInformation_container_progress_bottom_box_card_item_top_co2_icon">
                      <img v-show="index-1 != orderStatus" src="../../assets/images/home/carbonFootprint.png" alt="">
                      <img v-show="index-1 == orderStatus" src="../../assets/images/home/carbonFootprint_white.png" alt="">
                    </div>
                    <div class="ShippingInformation_container_progress_bottom_box_card_item_top_co2_text">
                      <p :class="['ShippingInformation_container_progress_bottom_box_card_item_top_co2_text_title', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_itemActive_top_co2_text_title' : '']">
                        {{ thousands(item.co) }}kg</p>
                      <p :class="['ShippingInformation_container_progress_bottom_box_card_item_top_co2_text_text', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_itemActive_top_co2_text_text' : '']">
                        CO<sub>2</sub></p>
                    </div>
                  </div>
                  <div class="ShippingInformation_container_progress_bottom_box_card_item_top_lable">
                    <img v-show="index-1 != order.orderStatus" src="../../assets/images/home/lable.png" alt="">
                    <img v-show="index-1 == order.orderStatus" src="../../assets/images/home/lable1.png" alt="">
                  </div>
                </div>

                <!-- 状态 -->
                <div :class="['ShippingInformation_container_progress_bottom_box_card_item_status', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_itemActive_status' : '']">
                  {{ item.state }}
                </div>

                <!-- 最后是日期和地点 -->
                <div :class="['ShippingInformation_container_progress_bottom_box_card_item_bottom', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_itemActive_bottom' : '']">
                  <div class="ShippingInformation_container_progress_bottom_box_card_item_bottom_date">
                    {{ item.time }}
                  </div>
                  <!--                  <div class="ShippingInformation_container_progress_bottom_box_card_item_bottom_location">-->
                  <!--                    China-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </div>

          <div ref="scrollContent" class="ShippingInformation_container_progress_bottom_box" v-if="order.orderType === 3">
            <div class="ShippingInformation_container_progress_bottom_box_card" v-for="(item, index) in stateOptionsWithSample " :key="index">
              <div class="ShippingInformation_container_progress_bottom_box_card_line">
                <div :class="['ShippingInformation_container_progress_bottom_box_card_line_round', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_line_roundActive' : 'ShippingInformation_container_progress_bottom_box_card_line_roundAlready',
                  index-1 > orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_line_roundNot' : '']">
                </div>
              </div>
              <div :class="['ShippingInformation_container_progress_bottom_box_card_item', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_itemActive' : '']">
                <div class="ShippingInformation_container_progress_bottom_box_card_item_top">
                  <div class="ShippingInformation_container_progress_bottom_box_card_item_top_co2">
                    <div class="ShippingInformation_container_progress_bottom_box_card_item_top_co2_icon">
                      <img v-show="index-1 != orderStatus" src="../../assets/images/home/carbonFootprint.png" alt="">
                      <img v-show="index-1 == orderStatus" src="../../assets/images/home/carbonFootprint_white.png" alt="">
                    </div>
                    <div class="ShippingInformation_container_progress_bottom_box_card_item_top_co2_text">
                      <p :class="['ShippingInformation_container_progress_bottom_box_card_item_top_co2_text_title', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_itemActive_top_co2_text_title' : '']">
                        {{ thousands(item.co) }}kg</p>
                      <p :class="['ShippingInformation_container_progress_bottom_box_card_item_top_co2_text_text', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_itemActive_top_co2_text_text' : '']">
                        CO<sub>2</sub></p>
                    </div>
                  </div>
                  <div class="ShippingInformation_container_progress_bottom_box_card_item_top_lable">
                    <img v-show="index-1 != order.orderStatus" src="../../assets/images/home/lable.png" alt="">
                    <img v-show="index-1 == order.orderStatus" src="../../assets/images/home/lable1.png" alt="">
                  </div>
                </div>

                <!-- 状态 -->
                <div :class="['ShippingInformation_container_progress_bottom_box_card_item_status', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_itemActive_status' : '']">
                  {{ item.state }}
                </div>

                <!-- 最后是日期和地点 -->
                <div :class="['ShippingInformation_container_progress_bottom_box_card_item_bottom', index-1 == orderStatus ? 'ShippingInformation_container_progress_bottom_box_card_itemActive_bottom' : '']">
                  <div class="ShippingInformation_container_progress_bottom_box_card_item_bottom_date">
                    {{ item.time }}
                  </div>
                  <!--                  <div class="ShippingInformation_container_progress_bottom_box_card_item_bottom_location">-->
                  <!--                    China-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 确认收货按钮 -->
      <div class="ShippingInformation_container_button">
        <button class="ShippingInformation_container_button_button" v-show="isConfirm" @click="confirmReceipt">
          Confirm delivery
        </button>

        <!-- 时间线弹窗按钮 -->
        <button class="ShippingInformation_container_button_button" @click="isShowTimeLine = true">
          Time Line
        </button>
        <!-- <div
          class="ShippingInformation_container_confirm"
          v-show="isConfirm"
        >
          <img
            src="../../assets/images/supplier/check-circle.png"
            alt=""
          >
          <span>Confirmed delivery</span>
        </div> -->
      </div>
    </div>

    <!-- 时间线弹窗 -->
    <div class="popup" v-if="isShowTimeLine">
      <timelinePopup :orderId="orderId" @close="isShowTimeLine = false"></timelinePopup>
    </div>
  </div>
</template>

<script setup name="ShippingInformation">
import { ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getOrderDetail, updateOrder, getOrderTimeLines } from '@/api/order'
import { useRoute, useRouter } from 'vue-router'
import { thousands } from '@/utils/thousands'
import timelinePopup from './dialog/timelinePopup.vue'

const router = useRouter()
const route = useRoute()
const scrollContainer = ref(null)
const scrollContent = ref(null)
const orderStatus = ref(0)
const scrollHorizontally = (event) => {
  event.preventDefault()
  const container = scrollContainer.value
  const content = scrollContent.value
  const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)))
  container.scrollLeft -= (delta * 30)
}
const stateOptionsOnlySample = ref([])
const stateOptions = ref([])
const stateOptionsWithSample = ref([])
const timeline = ref([])
const order = ref({})
const containerNumber = ref('')
const orderId = route.query.orderId
if (route.query.containerNumber) {
  containerNumber.value = route.query.containerNumber
}

// 是否显示时间线弹窗
const isShowTimeLine = ref(false)

console.log('orderId:' + orderId)

const currentStatus = async () => {
  const result = await getOrderDetail(orderId)
  if (result.code === 200) {
    order.value = result.data
    console.log(order.value)
    if (order.value.orderType === 1) {
      // 样品
      if (order.value.orderStatus === 101 || order.value.orderStatus === 102 || order.value.orderStatus === 103 || order.value.orderStatus < 9) {
        isConfirm.value = true
      } else {
        isConfirm.value = false
      }
    } else {
      // 非样品单
      if (order.value.orderStatus === 101 || order.value.orderStatus === 102 || order.value.orderStatus === 103 || order.value.orderStatus < 9) {
        isConfirm.value = true
      } else {
        isConfirm.value = false
      }
    }
    console.log('isConfirm', isConfirm.value)
  }
}
currentStatus()
const getOrderTimeLine = async () => {
  const result = await getOrderTimeLines(orderId)
  console.log('timeline result==', result)
  if (result.code === 200) {
    const timelineList = result.data
    timeline.value = timelineList
    if (timelineList.length > 0) {
      console.log('orderStatus==', order.value.orderStatus)
      console.log('orderType==', order.value.orderType)
      if (order.value.orderType === 1) {
        for (const item of timelineList) {
          for (const items of stateOptionsOnlySample.value) {
            if (item.status === items.status) {
              items.time = item.createTime
            }
          }
        }
      } else if (order.value.orderType === 2) {
        for (const item of timelineList) {
          for (const items of stateOptions.value) {
            if (item.status === items.status) {
              items.time = item.createTime
            }
          }
        }
      } else if (order.value.orderType === 3) {
        for (const item of timelineList) {
          for (const items of stateOptionsWithSample.value) {
            if (item.status === items.status) {
              items.time = item.createTime
            }
          }
        }
      }
    }
  }
}

const lateInit = () => {
  setTimeout(() => {
    if (order.value.orderType === 1) {
      switch (order.value.orderStatus) {
        case 2:
          orderStatus.value = 0
          break
        case 101:
          orderStatus.value = 1
          break
        case 102:
          orderStatus.value = 2
          break
        case 103:
          orderStatus.value = 2
          break
        case 9:
          orderStatus.value = 2
          break
      }
    } else if (order.value.orderType === 2) {
      switch (order.value.orderStatus) {
        case 2:
          orderStatus.value = 0
          break
        case 3:
          orderStatus.value = 1
          break
        case 4:
          orderStatus.value = 2
          break
        case 5:
          orderStatus.value = 3
          break
        case 6:
          orderStatus.value = 4
          break
        case 7:
          orderStatus.value = 5
          break
        case 8:
          orderStatus.value = 6
          break
        case 9:
          orderStatus.value = 6
          break
      }
    } else if (order.value.orderType === 3) {
      switch (order.value.orderStatus) {
        case 2:
          orderStatus.value = 0
          break
        case 101:
          orderStatus.value = 1
          break
        case 102:
          orderStatus.value = 2
          break
        case 3:
          orderStatus.value = 3
          break
        case 4:
          orderStatus.value = 4
          break
        case 5:
          orderStatus.value = 5
          break
        case 6:
          orderStatus.value = 6
          break
        case 7:
          orderStatus.value = 7
          break
        case 8:
          orderStatus.value = 8
          break
        case 9:
          orderStatus.value = 8
          break
      }
    }
    stateOptionsOnlySample.value = [
      {
        state: 'Order Created',
        time: '',
        status: 0,
        co: 0
      },
      {
        state: 'Raw Matrial Processing',
        time: '',
        status: 2,
        co: (order.value.choosedSupplierQuotationList ? (order.value.choosedSupplierQuotationList[0].materialCo ? order.value.choosedSupplierQuotationList[0].materialCo : 0) : 0)
      },
      {
        state: 'Sample Production',
        time: '',
        status: 101,
        co: (order.value.choosedSupplierQuotationList ? (order.value.choosedSupplierQuotationList[0].sampleProductionCo ? order.value.choosedSupplierQuotationList[0].sampleProductionCo : 0) : 0)
      },
      {
        state: 'Sample Shipping',
        time: '',
        status: 102,
        co: (order.value.choosedSupplierQuotationList ? (order.value.choosedSupplierQuotationList[0].sampleCo ? order.value.choosedSupplierQuotationList[0].sampleCo : 0) : 0)
      }

    ]

    stateOptions.value = [
      {
        state: 'Order Created',
        time: '',
        status: 0,
        co: 0
      },
      {
        state: 'Raw Matrial Processing',
        time: '',
        status: 2,
        co: (order.value.choosedSupplierQuotationList[0].materialCo ?? 0)
      },
      {
        state: 'Production',
        time: '',
        status: 3,
        co: (order.value.choosedSupplierQuotationList[0].energyCo ?? 0) + (order.value.choosedSupplierQuotationList[0].materialCo ?? 0)
      },
      {
        state: 'Packaging',
        time: '',
        status: 4,
        co: (order.value.choosedSupplierQuotationList[0].packagingCo ?? 0)
      },
      {
        state: 'Inspection',
        time: '',
        status: 5,
        co: 0
      },
      {
        state: 'Sending to Port of Departure',
        time: '',
        status: 6,
        co: (order.value.choosedSupplierQuotationList[0].factoryPortCo ?? 0)
      },
      {
        state: 'In Transit',
        time: '',
        status: 7,
        co: (order.value.choosedSupplierQuotationList[0].internationalTransCo ?? 0)
      },
      {
        state: 'Local Delivery',
        time: '',
        status: 8,
        co: (order.value.choosedSupplierQuotationList[0].requireCo ?? 0)
      }
    ]

    stateOptionsWithSample.value = [
      {
        state: 'Order Created',
        time: '',
        status: 0,
        co: 0
      },
      {
        state: 'Raw Matrial Processing',
        time: '',
        status: 2,
        co: (order.value.choosedSupplierQuotationList[0].materialCo ?? 0)
      },
      {
        state: 'Sample Production',
        time: '',
        status: 101,
        co: (order.value.choosedSupplierQuotationList[0].sampleProductionCo ?? 0)
      },
      {
        state: 'Sample Shipping',
        time: '',
        status: 102,
        co: (order.value.choosedSupplierQuotationList[0].sampleCo ?? 0)
      },
      {
        state: ' Mass Production',
        time: '',
        status: 3,
        co: (order.value.choosedSupplierQuotationList[0].energyCo ?? 0) + (order.value.choosedSupplierQuotationList[0].materialCo ?? 0)
      },
      {
        state: 'Packaging',
        time: '',
        status: 4,
        co: (order.value.choosedSupplierQuotationList[0].packagingCo ?? 0)
      },
      {
        state: 'Inspection',
        time: '',
        status: 5,
        co: 0
      },
      {
        state: 'Sending to Port of Departure',
        time: '',
        status: 6,
        co: (order.value.choosedSupplierQuotationList[0].factoryPortCo ?? 0)
      },
      {
        state: 'In Transit',
        time: '',
        status: 7,
        co: (order.value.choosedSupplierQuotationList[0].internationalTransCo ?? 0)
      },
      {
        state: 'Local Delivery',
        time: '',
        status: 8,
        co: (order.value.choosedSupplierQuotationList[0].requireCo ?? 0)
      }
    ]
    getOrderTimeLine()
  }, 1000)
}
lateInit()

// 是否已经收货
const isConfirm = ref(false)

// 确认收货
const confirmReceipt = () => {
  ElMessageBox.confirm(
    'I confirm that the goods have been received.',
    'Confirm delivery',
    {
      distinguishCancelAndClose: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      autofocus: false
    }
  )
    .then(() => {
      console.log('确认收货')
      confirmOrder()
    })
    .catch(() => {
      console.log('取消')
    })
}
const confirmOrder = async () => {
  // if (order.value.orderType === 1) {
  //   // 样品单
  //   const result = await updateOrder({ orderId: orderId, orderStatus: 103 })
  //   if (result.code === 200) {
  //     currentStatus()
  //     isConfirm.value = true
  //     router.back()
  //   }
  // } else {
  //   // 非样品单
  //   const result = await updateOrder({ orderId: orderId, orderStatus: 9 })
  //   if (result.code === 200) {
  //     currentStatus()
  //     isConfirm.value = true
  //     router.back()
  //   }
  // }
  const result = await updateOrder({ orderId: orderId, orderStatus: 9 })
  if (result.code === 200) {
    currentStatus()
    isConfirm.value = true
    router.back()
  }
}
// 引入网站并添加集装箱号
const iframeUrl = ref('')

// 集装箱号

// 货运公司，！！！！！！今天查询已限额，不知道去掉会不会有影响，0512
const shippingCompany = ref('COSU')

onMounted(() => {
  iframeUrl.value = 'https://www.searates.com/container/tracking/?container=' + containerNumber.value

  // ！！！！！！今天查询已限额，不知道去掉会不会有影响，0512
  // iframeUrl.value = 'https://www.searates.com/container/tracking/?container=' + containerNumber.value +'&sealine='+ shippingCompany.value
})

</script>
<style>
.el-affix {
  position: relative;
  z-index: 1;
}
</style>

<style lang="scss" scoped>
.ShippingInformation {
  line-height: 1;
  text-align: left;
  z-index: 1;

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  p {
    margin: 0;
  }

  &_container {
    position: relative;

    &_map {
      width: 100%;
      height: 100vh;
      overflow: hidden;
      // position: fixed;
      font-size: 0;
      margin-top: -72px;
      position: relative;
      z-index: 0;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    &_progress {
      position: absolute;
      bottom: -100px;
      display: flex;
      flex-direction: column;
      padding: 30px;
      background-color: rgba(0, 0, 0, 0.02);
      backdrop-filter: blur(5px);
      width: calc(100% - 60px);

      ::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &_top {
        display: flex;
        justify-content: space-between;
        z-index: 99;
        margin-bottom: 10px;

        &_left {
          text-align: left;
        }

        &_rifht {
          text-align: right;
        }

        &_title {
          font-size: 16px;
          font-weight: 700;
          color: rgba(24, 24, 24, 1);
          margin: 0;
          margin-bottom: 10px;
        }

        &_date {
          font-size: 12px;
          font-weight: 300;
          color: rgba(24, 24, 24, 0.7);
          margin: 10px 0 0 0 !important;
        }
      }

      &_bottom {
        overflow: auto;
        // margin-top: -30px;

        &_box {
          display: flex;

          &_card {
            display: flex;
            flex-direction: column;
            width: 300px;
            margin-right: 20px;
            flex-shrink: 0;

            &_line {
              margin-bottom: 30px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;

              &_round {
                width: 20px;
                height: 20px;
                border-radius: 50px;
                box-sizing: border-box;
                border: 2px solid rgba(39, 174, 96, 1);
                display: flex;
                justify-content: flex-end;
                align-items: center;
                z-index: 0;

                &::after {
                  flex-shrink: 0;
                  content: "";
                  display: block;
                  width: 325px;
                  height: 1px;
                  border-radius: 2px;
                  background-color: rgba(39, 174, 96, 0.1);
                }
              }

              // 货物当前地点样式
              &_roundActive {
                width: 20px;
                height: 20px;
                border: 2px solid rgba(39, 174, 96, 1);
                background-color: rgba(39, 174, 96, 1);

                &::after {
                  background-color: rgba(39, 174, 96, 1);
                }
              }

              // 货物已经过的地点样式
              &_roundAlready {
                width: 12px;
                height: 12px;
                // border: 3px solid rgba(39, 174, 96, 1);
                background-color: rgba(39, 174, 96, 1);

                &::after {
                  background-color: rgba(39, 174, 96, 1);
                }
              }

              // 货物未经过的地点样式
              &_roundNot {
                width: 20px;
                height: 20px;
                border: 2px solid rgba(173, 173, 173, 1);
                background-color: rgba(255, 255, 255, 0);

                &::after {
                  background-color: rgba(173, 173, 173, 0.1);
                }
              }
            }

            &_item {
              // height: 160px;
              background-color: rgb(255, 255, 255);
              border-radius: 6px;

              &_top {
                margin: 30px 0 20px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &_co2 {
                  display: flex;
                  font-size: 0;

                  img {
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                  }

                  &_text {
                    display: flex;

                    &_title {
                      font-size: 16px;
                      font-weight: 700;
                      color: rgba(39, 174, 96, 1);
                      margin-right: 5px !important;
                    }

                    &_text {
                      font-size: 14px;
                      line-height: 16px;
                      font-weight: 300;
                      color: rgba(36, 89, 2, 0.5);

                      sub {
                        font-size: 12px;
                      }
                    }
                  }
                }

                &_lable {
                  font-size: 0;
                  margin-right: 20px;

                  img {
                    width: 16px;
                    height: 16px;
                  }
                }
              }

              &_status {
                font-size: 16px;
                line-height: 24px;
                color: rgba(24, 24, 24, 0.7);
                margin: 0 20px;
              }

              &_bottom {
                margin: 30px 20px;
                display: flex;
                justify-content: space-between;
                color: rgba(24, 24, 24, 0.7);
                font-size: 12px;
              }
            }

            &_itemActive {
              // height: 160px;
              background-color: rgba(39, 174, 96, 1);
              border-radius: 6px;

              &_top {
                margin: 30px 0 20px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &_co2 {
                  display: flex;
                  font-size: 0;

                  img {
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                  }

                  &_text {
                    display: flex;

                    &_title {
                      font-size: 16px;
                      font-weight: 700;
                      color: rgba(255, 255, 255, 1);
                      margin-right: 5px !important;
                    }

                    &_text {
                      font-size: 14px;
                      line-height: 16px;
                      font-weight: 300;
                      color: rgba(255, 255, 255, 1);

                      sub {
                        font-size: 12px;
                      }
                    }
                  }
                }

                &_lable {
                  font-size: 0;
                  margin-right: 20px;

                  img {
                    width: 16px;
                    height: 16px;
                  }
                }
              }

              &_status {
                font-size: 16px;
                line-height: 24px;
                color: rgba(255, 255, 255, 1);
                margin: 0 20px;
              }

              &_bottom {
                margin: 30px 20px;
                display: flex;
                justify-content: space-between;
                color: rgba(255, 255, 255, 1);
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    &_button {
      width: 100%;
      position: absolute;
      bottom: -500px;
      padding-bottom: 300px;
      display: flex;
      justify-content: center;
      align-items: center;

      &_button {
        background-color: rgba(39, 174, 96, 1);
        border: none;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        padding: 20px 30px;
        border-radius: 4px;
        margin: 0 20px;
        cursor: pointer;
      }
    }

    &_confirm {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }

      span {
        color: rgba(39, 174, 96, 1);
        font-size: 16px;
      }
    }
  }
}
</style>
